import { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Loader, DatePicker } from 'rsuite';
import { getAllClients } from '../../../../api/clients';

const ClientSubscriptionForm = ({ clientSubscription, onSubmit, onDelete }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
    const [subscriptionExpirationDate, setSubscriptionExpirationDate] = useState(null);

    useEffect(() => {
        getAllClients()
            .then((result) => {
                setClients(result.data);
            })
            .catch((error) => {
                console.error('Failed to fetch clients:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (clientSubscription) {
            setSubscriptionStartDate(new Date(clientSubscription.subscriptionStartDate));
            setSubscriptionExpirationDate(new Date(clientSubscription.subscriptionExpirationDate));
        }
    }, [clientSubscription]);

    if (loading) {
        return <Loader />;
    }

    return (
        <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = {
                    productName: formData.get('productName'),
                    subscriptionStartDate: subscriptionStartDate.toISOString(),
                    subscriptionExpirationDate: subscriptionExpirationDate.toISOString(),
                    mentions: formData.get('mentions'),
                    clientId: formData.get('clientId'),
                };
                onSubmit(data);
            }}
        >
            <Row>
                <Col>
                    <Card className='card-modern card-big-info'>
                        <Card.Body>
                            <Row>
                                <Col lg='2-5' xl='1-5'>
                                    <i className='card-big-info-icon bx bx-user-circle'></i>
                                    <h2 className='card-big-info-title'>Informații Abonament</h2>
                                </Col>
                                <Col lg='3-5' xl='4-5'>
                                    {/* Product Name */}
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Nume Produs
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='productName'
                                                defaultValue={clientSubscription?.productName}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Mentiuni
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                as='textarea'
                                                className='form-control-modern'
                                                name='mentions'
                                                defaultValue={clientSubscription?.mentions}
                                            />
                                        </Col>
                                    </Form.Group>

                                    {/* Subscription Start Date */}
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Data incepere abonament
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <DatePicker
                                                className='form-control-modern w-100'
                                                name='subscriptionStartDate'
                                                value={subscriptionStartDate}
                                                onChange={setSubscriptionStartDate}
                                                format='yyyy-MM-dd'
                                                oneTap
                                                required
                                            />
                                        </Col>
                                    </Form.Group>

                                    {/* Subscription Expiration Date */}
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Data expirare abonament
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <DatePicker
                                                className='form-control-modern w-100'
                                                name='subscriptionExpirationDate'
                                                value={subscriptionExpirationDate}
                                                onChange={setSubscriptionExpirationDate}
                                                format='yyyy-MM-dd'
                                                oneTap
                                                required
                                            />
                                        </Col>
                                    </Form.Group>

                                    {/* Client Select Dropdown */}
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Client
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <input
                                                type='text'
                                                list='clientsList'
                                                // name='clientId'
                                                // id='inputClientId'
                                                placeholder='Selectează un client'
                                                className='w-100 form-control-modern form-control'
                                                defaultValue={
                                                    clients.find((client) => client.id === clientSubscription?.clientId)?.clientName
                                                }
                                                onInput={(e) => {
                                                    const selectedClient = clients.find((client) => client.clientName === e.target.value);
                                                    document.getElementById("inputClientId").value = selectedClient ? selectedClient.id : "";
                                                }}
                                                required
                                            />
                                            <datalist id='clientsList'>
                                                {/* Show clients as options in input */}
                                                {clients.map((client) => (
                                                    <option key={client.id} value={client.clientName} />
                                                ))}
                                            </datalist>
                                            <input type="hidden" name="clientId" id="inputClientId" defaultValue={clientSubscription?.clientId} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='action-buttons'>
                <Col md='auto' className='col-6'>
                    <Button
                        type='submit'
                        className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                        variant='primary'
                    >
                        <i className='bx bx-save text-4 mr-2'></i> Salvează
                    </Button>
                </Col>
                <Col md='auto' className='col-6 px-md-0 mt-0'>
                    <Button
                        onClick={() => navigate(-1)}
                        className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                        variant='light'
                    >
                        Înapoi
                    </Button>
                </Col>
                {onDelete && (
                    <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                        <Button
                            href='#delete'
                            className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                            variant='danger'
                            onClick={onDelete}
                        >
                            <i className='bx bx-trash text-4 mr-2'></i> Șterge
                        </Button>
                    </Col>
                )}
            </Row>
        </Form>
    );
};

export default ClientSubscriptionForm;