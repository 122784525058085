import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { getKeyStorageByKeys } from '../../../api/key-storage';
import Loader from '../../../features/loader';
import KeyStorageInfo from './key-storage-info';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { getListApi } from '../../../api/category';
import { getSuppliersApi } from '../../../api/supplier';
import * as XLSX from 'xlsx';
import { format } from 'rsuite/esm/utils/dateUtils';
import JSZip from 'jszip';
import { createCustomActivityLog } from '../../../api/activity-logs';

export default function KeyStorageSearch() {
    const [loading, setLoading] = useState(true);
    const [isFilteredDuplicates, setIsFilteredDuplicates] = useState(false);
    const [searchedKeys, setSearchedKeys] = useState({
        initialKeySearch: [],
        keys: [],
        filteredKeys: [],
    });
    const [categoriesObject, setCategories] = useState({
        categories: [],
        currentCategory: '',
    })
    const [suppliersObject, setSuppliers] = useState({
        suppliers: [],
        currentSupplier: '',
    })
    const formRef = useRef();

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            if (categoriesObject.categories && !categoriesObject.categories.length) {
                const result = await getListApi();
                if (result.status === 200) {
                    setCategories(prev => ({
                        ...prev,
                        categories: result.data.message,
                    }))
                }
            }
            if (suppliersObject.suppliers && !suppliersObject.suppliers.length) {
                const result = await getSuppliersApi();
                if (result.status === 200) {
                    setSuppliers(prev => ({
                        ...prev,
                        suppliers: result.data.message,
                    }))
                }
            }
            setLoading(false);
        }
        fetchData();
    }, [])

    const searchKeys = async (e) => {
        e.preventDefault();
        setLoading(true);
        const keysArray = getKeysArray(e?.target?.keys?.value ?? '');
        setSearchedKeys({
            initialKeySearch: keysArray,
            keys: [],
            filteredKeys: [],
        });
        if (keysArray && keysArray.length) {
            await getKeyStorageByKeys(keysArray).then((result) => {
                if (result.status === 200 && result.data.error === 0) {
                    const sortedKeys = result.data.message.sort((a, b) => a.category_id - b.category_id);
                    setSearchedKeys(prev => ({
                        ...prev,
                        keys: sortedKeys,
                        filteredKeys: filterKeys(sortedKeys, categoriesObject.currentCategory, suppliersObject.currentSupplier, isFilteredDuplicates),
                    }));
                } else {
                    return toast(
                        <PNotify
                            title='Error'
                            icon='fas fa-check'
                            text={result.data.message}
                        />,
                        {
                            containerId: 'default',
                            className: 'notification-danger',
                        }
                    );
                }
            });
        }
        setLoading(false);
    };


    const handleCategoryChange = (e) => {
        e.persist();
        const newCategory = e.target.value;
        setCategories(prev => ({
            ...prev,
            currentCategory: newCategory,
        }));
        setSearchedKeys(prev => ({
            ...prev,
            filteredKeys: filterKeys(prev.keys, newCategory, suppliersObject.currentSupplier, isFilteredDuplicates),
        }));
    };

    const handleSupplierChange = (e) => {
        e.persist();
        const newSupplier = e.target.value;
        setSuppliers(prev => ({
            ...prev,
            currentSupplier: newSupplier,
        }));
        setSearchedKeys(prev => ({
            ...prev,
            filteredKeys: filterKeys(prev.keys, categoriesObject.currentCategory, newSupplier, isFilteredDuplicates),
        }));
    };

    const handleDuplicateChange = (e) => {
        e.persist();
        const filterDupe = e.target.checked;
        setIsFilteredDuplicates(filterDupe);
        setSearchedKeys(prev => ({
            ...prev,
            filteredKeys: filterKeys(prev.keys, categoriesObject.currentCategory, suppliersObject.currentSupplier, filterDupe),
        }));
    };


    const filterKeys = (keys, category, supplier, isFilteredDuplicates) => {
        let filtered = keys.filter(key => {
            return (!category || key.category_id == category) && (!supplier || key.supplier_id == supplier);
        });

        if (isFilteredDuplicates) {
            const latestKeysMap = new Map();

            // Iterate over filtered keys and keep the latest one based on updated_at
            filtered.forEach(key => {
                const existing = latestKeysMap.get(key.key);
                if (!existing || new Date(key.updated_at) > new Date(existing.updated_at)) {
                    latestKeysMap.set(key.key, key);
                }
            });

            // Convert map values to an array
            filtered = Array.from(latestKeysMap.values());
        }

        return filtered;
    };

    function handleTextAreaChange(event) {
        const textarea = event.target;
        const lines = textarea.value.split('\n');
        const rows = lines.length > 1 ? lines.length + 1 : 2;
        textarea.rows = rows;
    }

    function getKeysArray(text) {
        const array = text.split('\n');
        for (let i = array.length - 1; i >= 0; i--) {
            const trimmedElement = array[i].replace(/\r/g, '').trim();
            if (trimmedElement === '' || trimmedElement.length <= 15) {
                array.splice(i, 1); // remove the current element from the array
            } else {
                array[i] = trimmedElement; // update the current element with the trimmed value
            }
        }
        return array;
    }

    const handleExport = async () => {
        const rows = await getExportData();
        if (!rows) { return }

        // Excel File Creation
        let objectMaxLength = await getExcelColumnWidths(rows);
        const wcols = objectMaxLength.map((value) => ({ wch: value })); // Width based on characters
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);
        worksheet['!cols'] = wcols; // Add column widths

        XLSX.utils.sheet_add_aoa(worksheet, [
            ["Key", "Category", "Supplier", "Invoice", 'Replacement Data', "Replacement", "Status"],
        ], { origin: 0 }); // Add table headers

        XLSX.utils.book_append_sheet(workbook, worksheet, "Keys");

        // Generate Excel binary data
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // TXT File Creation
        const filteredKeysSet = new Set(searchedKeys.filteredKeys.map(key => key.key)); // Keys already exported
        const initialKeySearchFiltered = searchedKeys.initialKeySearch
            .filter(key => !filteredKeysSet.has(key)); // Filter out exported keys
        const data = { description: "Utilizatorul a dat export la key search" };
        await createCustomActivityLog(data)
        if (!initialKeySearchFiltered.length) {
            // If no TXT content, directly download the Excel file
            const url = URL.createObjectURL(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const a = document.createElement('a');
            a.href = url;
            a.download = `Invalid_Keys_${format(new Date(), 'dd_mm_yyyy')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            return;
        }

        const txtContent = initialKeySearchFiltered.join('\n'); // Join key strings with newline
        const txtBlob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });

        // Create ZIP File if TXT content exists
        const zip = new JSZip();
        zip.file(`Invalid_Keys_${format(new Date(), 'dd_mm_yyyy')}.xlsx`, excelBuffer);
        zip.file(`Missing_Keys_${format(new Date(), 'dd_mm_yyyy')}.txt`, txtBlob);

        // Generate the ZIP and trigger download
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const url = URL.createObjectURL(content);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Exported_Data_${format(new Date(), 'dd_mm_yyyy')}.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    async function getExportData() {
        if (!searchedKeys.filteredKeys.length) {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={'No data to export!'}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
        const exportData = searchedKeys.filteredKeys.reduce((acc, obj) => {
            acc.push({
                key: obj.key,
                category: obj.catLicenseCatModule.name,
                supplier: obj.supplier.company_name,
                invoice: obj.invoice?.invoice_name || '-',
                dateAdded: obj?.date_added || '-',
                replacement: obj.replacement,
                status: obj.status.name,
            })
            return acc;
        }, []);
        return exportData;
    }

    async function getExcelColumnWidths(rows) {
        // TODO DELETE THIS IF APPLICATION TOO SLOW AND ADD FIXED WIDTH VALUE
        let objectMaxLength = [];
        for (let i = 0; i < rows.length; i++) {
            let value = Object.values(rows[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                }
            }
        }
        //
        return objectMaxLength;
    }

    return (
        <>
            <Breadcrumb
                current={'Key Storage Search'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Gestionare Chei',
                        url: '#',
                    },
                    {
                        name: 'Key Storage Search',
                        url: '#',
                    },
                ]}
            />
            <Row>
                <Col>
                    <Form className='core-form form'
                        action='#'
                        method='post'
                        onSubmit={searchKeys}
                        id='myForm'
                        ref={formRef}
                    >
                        <Card className='card-modern card-big-info'>
                            <Card.Body>
                                <Row lg={4} xl={3} className='d-flex justify-content-center align-items-stretch'>
                                    <Col lg={5} xl={2} className='d-flex flex-column justify-content-center align-items-center'>
                                        <i className='card-big-info-icon bx bx-search-alt'></i>
                                        <h2 className='card-big-info-title'>Search</h2>
                                    </Col>
                                    <Col lg={10} xl={10} className='align-self-center'>
                                        <Form.Group as={Row} className='align-items-center justify-content-center'>
                                            <Col xs={12} lg={10} xl={11} className='text-center text-lg-right'>
                                                <textarea
                                                    name='keys'
                                                    id='keys'
                                                    placeholder="Search Keys"
                                                    className='w-100 form-control-modern form-control pl-3 pt-3 pb-0'
                                                    style={{ borderRadius: '10px' }}
                                                    onChange={handleTextAreaChange}
                                                    rows={3} // Adjust the number of rows as needed
                                                />
                                                <div className='d-flex align-items-center flex-wrap justify-content-between justify-content-lg-between mt-3'>
                                                    <Form.Control
                                                        as='select'
                                                        className='select-style-1 filter-by my-1 mr-2 col-md-4 col-xs-12'
                                                        value={categoriesObject.currentCategory}
                                                        onChange={handleCategoryChange}
                                                    >
                                                        <option value=''>All categories</option>
                                                        {categoriesObject.categories
                                                            ? categoriesObject.categories.map((data) => {
                                                                return (
                                                                    <option key={data.name} value={data.id}>
                                                                        {data.name}
                                                                    </option>
                                                                );
                                                            })
                                                            : ''}
                                                    </Form.Control>
                                                    <Form.Control
                                                        as='select'
                                                        className='select-style-1 filter-by my-1 mr-2 col-md-4 col-xs-12'
                                                        value={suppliersObject.currentSupplier}
                                                        onChange={handleSupplierChange}
                                                    >
                                                        <option value=''>All suppliers</option>
                                                        {suppliersObject.suppliers
                                                            ? suppliersObject.suppliers.map((data) => {
                                                                return (
                                                                    <option key={data.company_name} value={data.id}>
                                                                        {data.company_name}
                                                                    </option>
                                                                );
                                                            })
                                                            : ''}
                                                    </Form.Control>
                                                    <Button type='submit' className='col col-md-auto col-xs-12 mr-2' onClick={(e) => {
                                                        e.preventDefault();
                                                        formRef.current.dispatchEvent(new Event('submit'));
                                                    }}>
                                                        <i className='bx bx-search-alt'></i>&nbsp;Search
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Filtrează licențele duplicate
                                            </Col>
                                            <Col className='align-items-center'>
                                                <input
                                                    className='checkbox-style-1 p-relative d-block'
                                                    type="checkbox"
                                                    id="isActivareEnabled"
                                                    name="isActivareEnabled"
                                                    defaultChecked={isFilteredDuplicates}
                                                    onChange={handleDuplicateChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
            {searchedKeys.filteredKeys && searchedKeys.filteredKeys.length ? (
                <>
                    {loading ? (
                        <Loader />
                    )
                        : (
                            <>
                                <Row>
                                    <Col >
                                        <Button className='btn btn-warning' onClick={handleExport}><i className='fa fa-file-excel'></i> Export</Button>
                                        <span className='ml-2'>{`${searchedKeys.filteredKeys.length} / ${searchedKeys.initialKeySearch.length}`} Keys found</span>
                                    </Col>
                                </Row>
                                <Row className='align-items-stretch'>
                                    {searchedKeys.filteredKeys.map((searchedKey) => {
                                        return (

                                            <Col key={searchedKey.id} lg={6} xl={6} className='d-flex flex-column'>
                                                <div className='flex-grow-1 justify-content-center align-items-center'>
                                                    <KeyStorageInfo
                                                        searchedKey={searchedKey}
                                                    />
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </>
                        )
                    }
                </>
            ) : (
                ''
            )
            }
        </>
    )
}

