import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getActivityLogsWithFilter(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/activity-logs`,
    withCredentials: true,
  };

  return axios(config)
    .then((response) => {
      let error = response.data.error;
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;

        // Apply filters
        filters &&
          filters.forEach((filter) => {
            results = results.filter((activityLog) => {
              return (
                activityLog['description']?.search(new RegExp(filter.value, 'i')) >= 0 ||
                activityLog['userFullName']?.search(new RegExp(filter.value, 'i')) >= 0 ||
                activityLog['email']?.search(new RegExp(filter.value, 'i')) >= 0 ||
                activityLog['role']?.search(new RegExp(filter.value, 'i')) >= 0 ||
                activityLog['createdAt']?.search(new RegExp(filter.value, 'i')) >= 0
              );
            });
          });

        // Apply sorting
        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'description':
                results = results.sort((a, b) =>
                  a.description < b.description ? -index : index
                );
                break;
              case 'userFullName':
                results = results.sort((a, b) =>
                  a.userFullName < b.userFullName ? -index : index
                );
                break;
              case 'email':
                results = results.sort((a, b) =>
                  a.email < b.email ? -index : index
                );
                break;
              case 'role':
                results = results.sort((a, b) =>
                  a.role < b.role ? -index : index
                );
                break;
              case 'createdAt':
                results = results.sort((a, b) =>
                  a.createdAt < b.createdAt ? -index : index
                );
                break;
              default:
                break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createCustomActivityLog(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/activity-logs`,
    data: data,
    withCredentials: true,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
}
