import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Modal from "react-modal";

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        maxHeight: '70%', // Add a max height (you can change this value)
        overflow: 'auto', // Enable scroll
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function loggedDetailsModal({ isOpen, onClose, details }) {

    function closeModal(result = false) {
        onClose(result);
    }

    const formatValue = (value) => {
        if (Array.isArray(value)) {
            // Format arrays
            return value.join("<br/>");
        } else if (typeof value === "object" && value !== null) {
            // Format objects recursively
            return Object.entries(value)
                .map(([innerKey, innerValue]) => `<b>${innerKey}</b>: ${Array.isArray(innerValue) ? innerValue.join(", ") : innerValue}`)
                .join("<br/>");
        }
        // Return primitive values directly
        return value;
    };

    const keyValues = Object.entries(details).map(([key, value]) => {
        if (value) {
            const formattedValue = formatValue(value);
            return (
                <div key={key}>
                    <b>{key}:&nbsp;</b>
                    <p dangerouslySetInnerHTML={{ __html: formattedValue }}></p>
                </div>
            );
        }
        return null;
    });

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={() => closeModal(false)}
        >
            <Card>
                <Card.Header>
                    <Card.Title>Modificari:</Card.Title>
                </Card.Header>
                <Card.Body>
                    <h4>{details['responseMessage']}</h4>
                    <div className="modal-wrapper d-flex justify-content-center flex-column">
                        {keyValues}
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={() => closeModal(false)}
                            >Ok</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}