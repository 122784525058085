import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Breadcrumb from '../common/breadcrumb';
import GeneratorControlPanel from '../../features/dashboard-widgets/generator-control-panel';
import LogViewer from '../../features/dashboard-widgets/log-viewer';
import OrderSearch from '../../features/dashboard-widgets/order-search';
import ClientSubscriptionsTable from './clients/components/client-subscriptions-table';
import { Link } from 'react-router-dom';
export default function Dashboard({ user }) {

  const columns = [
    {
      Header: 'Nume Client',
      accessor: 'clientName',
      sortable: true,
      Cell: (row) => {
        const client = row?.original?.client ?? null;
        return (
          client.clientName
        )
      }
    },
    {
      Header: 'Email',
      accessor: 'contactEmail',
      sortable: true,
      Cell: (row) => {
        const client = row?.original?.client ?? null;
        return (
          client.contactEmail
        )
      }
    },
    {
      Header: 'Data incepere abonament',
      accessor: 'subscriptionStartDate',
      sortable: true,
      Cell: (row) => {
        const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
        return (
          formattedDate
        )
      }
    },
    {
      Header: 'Data expirare abonament',
      accessor: 'subscriptionExpirationDate',
      sortable: true,
      Cell: (row) => {
        const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
        return (
          formattedDate
        )
      }
    },
    {
      Header: 'Client',
      accessor: 'client',
      sortable: true,
      Cell: (row) => {
        const client = row?.original?.client ?? null;
        return (
          client && (
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/client/${client.id}`}
              className='on-default edit-row mr-2'
            >
              {client.clientName}
            </Link>
          ) || ("-")
        )
      }
    },
  ];

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  return (
    <>
      <Breadcrumb current='Dashboard' />
      <Row>
        <Col xs={12} xl={12} xxl={12}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} className='col-4'>
              <Card>
                <Card.Body className='p-0'>
                  <GeneratorControlPanel user={user} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} xl={8} xxl={8} className="pt-2 pt-xl-0 mt-4 mt-xl-0">
              <Row className="h-100">
                <Col>
                  <Card className="card-modern h-100">
                    <Card.Header>
                      <Card.Title>Logs</Card.Title>
                    </Card.Header>
                    <Card.Body className="h-100 pt-2">
                      <LogViewer />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="card-modern h-100">
            <Card.Header>
              <Card.Title>Abonamente</Card.Title>
            </Card.Header>
            <Card.Body className="h-100">
              <ClientSubscriptionsTable pageSize={5} columns={columns} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="card-modern h-100">
            <Card.Header>
              <Card.Title>Caută comandă</Card.Title>
            </Card.Header>
            <Card.Body className="h-100 pt-2">
              <OrderSearch user={user} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
