import axios from "axios";

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';
export async function getClients(page, pageSize, filters, sortBy) {

    const columnsToFilter = ['contactEmail', 'clientName', 'address', 'postalCode', 'cui', 'contactName', 'phone'];
    const filtersQueryParam = filters ? `&filters=${encodeURIComponent(JSON.stringify({ filters, columnsToFilter }))}` : '';
    const sortByQueryParam = sortBy ? `&sortBy=${encodeURIComponent(JSON.stringify(sortBy))}` : '';

    const url = `${API_URL}/clients?page=${page}&pageSize=${pageSize}${filtersQueryParam}${sortByQueryParam}`;
    try {
        const response = await axios.get(url, { withCredentials: true });
        return {
            data: response.data.message,
            total: response.data.total,
            totalResults: response.data.totalResults,
        };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function getAllClients() {
    const url = `${API_URL}/clients/all`;
    try {
        const response = await axios.get(url, { withCredentials: true });
        return {
            data: response.data.message,
        };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function getClient(id) {
    const url = `${API_URL}/clients/${id}`;
    try {
        const response = await axios.get(url, { withCredentials: true });
        return response.data.message;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function addClient(data) {
    const config = {
        method: 'post',
        url: `${API_URL}/clients`,
        withCredentials: true,
        data: data,

    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}

export async function updateClient(data, id) {
    const config = {
        method: 'patch',
        url: `${API_URL}/clients/${id}`,
        withCredentials: true,
        data: data,

    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}

export async function deleteClients(id) {
    const config = {
        method: 'delete',
        url: `${API_URL}/clients/${id}`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}