import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import PNotify from '../../../features/elements/p-notify';
import { addClientSubscription } from '../../../api/client-subscriptions';
import ClientSubscriptionForm from './components/client-subscription-form';

export default function ClientSubscriptionCreate() {
    const navigate = useNavigate();

    const addItem = async (data) => {
        const result = await addClientSubscription(data);
        if (result.status === 200 && result.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/client-subscriptions`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    return (
        <>
            <Breadcrumb
                current='Adauga Abonament'
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Clienti si Abonamente',
                        url: '#',
                    },
                    {
                        name: 'Abonamente',
                        url: '/dashboard/clients/subscriptions',
                    },
                    {
                        name: 'Adauga Abonament',
                        url: '#',
                    },
                ]}
            />
            <ClientSubscriptionForm
                onSubmit={addItem}
            />
        </>
    )
}