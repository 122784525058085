import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import PNotify from '../../../features/elements/p-notify';
import { addClient } from '../../../api/clients';
import ClientForm from './components/client-form';

export default function ClientCreate() {
    const navigate = useNavigate();

    const addItem = async (e) => {
        e.preventDefault();
        let data = {
            contactEmail: e.target.contactEmail.value,
            clientName: e.target.clientName.value,
            address: e.target.address.value,
            postalCode: e.target.postalCode.value,
            cui: e.target.cui.value,
            contactName: e.target.contactName.value,
            phone: e.target.phone.value,
        }

        const result = await addClient(data);
        if (result.status === 200 && result.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/clients`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    return (
        <>
            <Breadcrumb
                current='Detalii Client'
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Clienti si Abonamente',
                        url: '#',
                    },
                    {
                        name: 'Clienti',
                        url: '/dashboard/clients',
                    },
                    {
                        name: 'Adauga Client',
                        url: '#',
                    },
                ]}
            />
            <ClientForm
                onSubmit={addItem}
            />
        </>
    )
}