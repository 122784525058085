import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';
import { deleteClients, getClient, updateClient } from '../../../api/clients';
import ClientForm from './components/client-form';
import ClientSubscriptionsTable from './components/client-subscriptions-table';

export default function ClientDetails() {

    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        getClient(id).then((result) => {
            setClient(result);
        }).finally(() => {
            setLoading(false);
        });
        document.querySelector('body').classList.add('loaded');
    }, [])

    const updateItem = async (e) => {
        e.preventDefault();
        let data = {
            contactEmail: e.target.contactEmail.value,
            clientName: e.target.clientName.value,
            address: e.target.address.value,
            postalCode: e.target.postalCode.value,
            cui: e.target.cui.value,
            contactName: e.target.contactName.value,
            phone: e.target.phone.value,
        }

        const result = await updateClient(data, id);
        if (result.status === 200 && result.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/clients`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    const deleteItem = (e) => {
        e.preventDefault();
        setOpenModal(true);
    }

    async function deleteConfirm(result) {
        setOpenModal(false);
        if (result) {
            const checker = await deleteClients(id);
            if (checker.status === 200 && checker.data.error === 0) {
                navigate(`${process.env.PUBLIC_URL}/dashboard/clients`);
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    return (
        loading ? (
            <Loader />
        ) : (
            <>
                <Breadcrumb
                    current='Detalii Client'
                    paths={[
                        {
                            name: 'Dashboard',
                            url: '/dashboard',
                        },
                        {
                            name: 'Clienti si Abonamente',
                            url: '#',
                        },
                        {
                            name: 'Clienti',
                            url: '/dashboard/clients',
                        },
                        {
                            name: 'Detalii Client',
                            url: '#',
                        },
                    ]}
                />
                <ClientForm
                    client={client}
                    onSubmit={updateItem}
                    onDelete={deleteItem}
                />
                <Row className='mt-5'>
                    <Col>
                        <h3>Abonamentele clientului</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ClientSubscriptionsTable clientId={id} />
                    </Col>
                </Row>
                <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
            </>
        )
    )
}