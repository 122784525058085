import axios from "axios"

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getClientSubscriptions(page, pageSize, filters, sortBy) {

    const columnsToFilter = ['productName', 'subscriptionStartDate', 'subscriptionExpirationDate', 'client.clientName', 'mentions'];
    const filtersQueryParam = filters ? `&filters=${encodeURIComponent(JSON.stringify({ filters, columnsToFilter }))}` : '';
    const sortByQueryParam = sortBy ? `&sortBy=${encodeURIComponent(JSON.stringify(sortBy))}` : '';

    const url = `${API_URL}/client/subscriptions?page=${page}&pageSize=${pageSize}${filtersQueryParam}${sortByQueryParam}`;

    try {
        const response = await axios.get(url, { withCredentials: true });
        return {
            data: response.data.message,
            total: response.data.total,
            totalResults: response.data.totalResults,
        };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function getClientSubscription(id) {
    const url = `${API_URL}/client/subscriptions/${id}`;
    try {
        const response = await axios.get(url, { withCredentials: true });
        return response.data.message;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function updateClientSubscription(data, id) {
    const config = {
        method: 'patch',
        url: `${API_URL}/client/subscriptions/${id}`,
        withCredentials: true,
        data: data,

    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}

export async function deleteClientSubscription(id) {
    const config = {
        method: 'delete',
        url: `${API_URL}/client/subscriptions/${id}`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}

export async function addClientSubscription(data) {
    const config = {
        method: 'post',
        url: `${API_URL}/client/subscriptions`,
        withCredentials: true,
        data: data,

    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}