import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ClientForm = ({ client, onSubmit, onDelete }) => {
    const navigate = useNavigate();

    return (
        <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={onSubmit}
        >
            <Row>
                <Col>
                    <Card className='card-modern card-big-info'>
                        <Card.Body>
                            <Row>
                                <Col lg='2-5' xl='1-5'>
                                    <i className='card-big-info-icon bx bx-user-circle'></i>
                                    <h2 className='card-big-info-title'>Informații Client</h2>
                                </Col>
                                <Col lg='3-5' xl='4-5'>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Nume Client
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='clientName'
                                                defaultValue={client?.clientName}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Email Contact
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='contactEmail'
                                                defaultValue={client?.contactEmail}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Adresa
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='address'
                                                defaultValue={client?.address}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Cod Postal
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='postalCode'
                                                defaultValue={client?.postalCode}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            CUI
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='cui'
                                                defaultValue={client?.cui}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Nume Contact
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='contactName'
                                                defaultValue={client?.contactName}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={5}
                                            xl={3}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Telefon
                                        </Col>
                                        <Col lg={7} xl={6}>
                                            <Form.Control
                                                className='form-control-modern'
                                                name='phone'
                                                defaultValue={client?.phone}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='action-buttons'>
                <Col md='auto' className='col-6'>
                    <Button
                        type='submit'
                        className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                        variant='primary'
                    >
                        <i className='bx bx-save text-4 mr-2'></i> Salvează
                    </Button>
                </Col>
                <Col md='auto' className='col-6 px-md-0 mt-0'>
                    <Button
                        onClick={() => navigate(-1)}
                        className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                        variant='light'
                    >
                        Înapoi
                    </Button>
                </Col>
                {onDelete && (
                    <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                        <Button
                            href='#delete'
                            className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                            variant='danger'
                            onClick={onDelete}
                        >
                            <i className='bx bx-trash text-4 mr-2'></i> Șterge
                        </Button>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

export default ClientForm;