import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PtTable from '../../../../features/elements/table';
import PNotify from '../../../../features/elements/p-notify';
import { deleteClientSubscription, getClientSubscriptions } from '../../../../api/client-subscriptions';

const ClientSubscriptionsTable = ({ clientId, pageSize, customColumns }) => {

    const [loading, setLoading] = useState(true);
    const [ajax, setAjax] = useState({
        data: [],
        total: 0,
        totalRecords: 0,
        totalFilteredRecords: 0,
        isFiltered: false,
    });

    // Filter Variables
    const [search, setSearch] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [tableRef, setTableRef] = useState(null);
    const [selected, setSelected] = useState([]);
    const [bulk, setBulk] = useState('');

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
            sortable: true,
        },
        {
            Header: 'Nume Produs',
            accessor: 'productName',
            sortable: true,
        },
        {
            Header: 'Mentiuni',
            accessor: 'mentions',
            sortable: true,
        },
        {
            Header: 'Data incepere abonament',
            accessor: 'subscriptionStartDate',
            sortable: true,
            Cell: (row) => {
                const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
                return (
                    formattedDate
                )
            }
        },
        {
            Header: 'Data expirare abonament',
            accessor: 'subscriptionExpirationDate',
            sortable: true,
            Cell: (row) => {
                const formattedDate = row.value ? new Date(row.value).toLocaleDateString() : '-';
                return (
                    formattedDate
                )
            }
        },
        {
            Header: 'Client',
            accessor: 'client',
            sortable: true,
            Cell: (row) => {
                const client = row?.original?.client ?? null;
                return (
                    client && (
                        <Link
                            to={`${process.env.PUBLIC_URL}/dashboard/client/${client.id}`}
                            className='on-default edit-row mr-2'
                        >
                            {client.clientName}
                        </Link>
                    ) || ("-")
                )
            }
        },
        {
            Header: 'Acțiuni',
            accessor: 'id',
            className: 'actions',
            headerClassName: 'justify-content-center',
            width: 100,
            Cell: (row) => (
                <>
                    <Link
                        to={`${process.env.PUBLIC_URL}/dashboard/client-subscription/${row.value}`}
                        className='on-default edit-row mr-2'
                    >
                        <i className='fas fa-pencil-alt'></i>
                    </Link>
                    <a
                        href='#del'
                        className='on-default remove-row'
                        onClick={(e) => deleteRow(e, row.value)}
                    >
                        <i className='far fa-trash-alt'></i>
                    </a>
                </>
            ),
        },
    ];

    useEffect(() => {
        setSelected((selected) =>
            selected.map((item) => {
                return {
                    ...item,
                    selected: selectAll,
                };
            })
        );
        document.querySelector('body').classList.add('loaded');
    }, [selectAll]);

    function isSelected(key) {
        return selected.find((item) => item.id === key && item.selected);
    }

    function onSelectChange(e, value, row) {
        setSelected(
            selected.map((item) => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            })
        );
    }

    async function deleteRow(e, index) {
        e.preventDefault();
        if (window.confirm('Ești sigur că vrei să ștergi aceste date?')) {

            // TODO change method to new api method
            const deleteItem = await deleteClientSubscription(index);
            if (deleteItem.status === 200) {
                setAjax({
                    ...ajax,
                    data: ajax.data.filter((item) => item.id !== index),
                    totalRecords: ajax.totalRecords - 1,
                    totalFilteredRecords: ajax.totalFilteredRecords - 1
                });
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    async function bulkAction(e) {
        e.preventDefault();
        if (!bulk) {
            return toast(
                <PNotify
                    title='Warning'
                    icon='fas fa-exclamation'
                    text='Please choose one of actions.'
                />,
                {
                    containerId: 'default',
                    className: 'notification-warning',
                }
            );
        }
        if (bulk === 'delete') {
            if (!selected.find((item) => item.selected)) {
                return toast(
                    <PNotify
                        title='Warning'
                        icon='fas fa-exclamation'
                        text='Choose at least one item.'
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-warning',
                    }
                );
            }

            let stringId = '';
            const bulkDeleteId = selected.filter((item) => item.selected);
            var bulkLength = bulkDeleteId.length;
            bulkDeleteId.forEach((item, index) => {
                if (index + 1 === bulkLength) {
                    stringId += item.id;
                } else {
                    stringId += item.id + '&';
                }
            });

            // TODO change api to new api
            const deleteItems = await deleteClientSubscription(stringId);
            if (deleteItems.status === 200) {
                setAjax({
                    ...ajax,
                    data: ajax.data.filter((media) =>
                        selected.find((item) => item.id === media.id && !item.selected)
                    ),
                    totalRecords: ajax.totalRecords - bulkLength,
                    totalFilteredRecords: ajax.totalFilteredRecords - bulkLength
                });
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={deleteItems.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={deleteItems.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    function searchTable(e) {
        e.preventDefault();
        tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
    }

    function fetchData(state) {
        let filtered = [...state.filtered];

        if (clientId) {
            filtered.push({ id: 'clientId', value: clientId });
        }

        setLoading(true);
        getClientSubscriptions(
            state.page + 1,
            state.pageSize,
            filtered,
            state.sorted
        ).then((results) => {
            if (results?.data) {
                setAjax({
                    data: results.data,
                    total:
                        parseInt(results.total / state.pageSize) +
                        !!(results.total % state.pageSize),
                    totalRecords: results.total,
                    isFiltered: filtered.length ? true : false,
                });
                setSelected(
                    results.data.map((media) => {
                        return {
                            id: media.id,
                            selected: false,
                        };
                    })
                );
                setSelectAll(false);
            }
            setLoading(false);
        });
    }


    const checkIfTwoWeeksBeforeExpiration = (expirationDate) => {
        const today = new Date();
        const twoWeeksBeforeExpiration = new Date(expirationDate);

        // Strip time from `today` and `twoWeeksBeforeExpiration`
        today.setHours(0, 0, 0, 0);
        twoWeeksBeforeExpiration.setHours(0, 0, 0, 0);

        // Move `twoWeeksBeforeExpiration` 14 days earlier
        twoWeeksBeforeExpiration.setDate(twoWeeksBeforeExpiration.getDate() - 14);

        // Compare dates without time
        return today >= twoWeeksBeforeExpiration;
    };


    const getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
            const isExpiringSoon = checkIfTwoWeeksBeforeExpiration(rowInfo.original.subscriptionExpirationDate)
            if (isExpiringSoon) {
                return {
                    style: {
                        background: '#FFCCCC',
                    }
                }
            }
        }
        return {};
    }

    return (
        <Row>
            <Col>
                <Form method='GET' action='#' onSubmit={searchTable}>
                    <Card className='card-modern'>
                        <Card.Body>
                            <div className='datatables-header-footer-wrapper'>
                                <div className='datatable-header'>
                                    <Row className='align-items-lg-center mb-3'>
                                        <Col xl='auto' className='mb-2 mt-1 mb-xl-0'>
                                            <Button
                                                as={Link}
                                                to={`${process.env.PUBLIC_URL}/dashboard/client-subscription/create${clientId ? `?clientId=${clientId}` : ''}`}
                                                className='font-weight-semibold'
                                                variant='primary'
                                                size='md'
                                            >
                                                + Adaugă abonament
                                            </Button>
                                        </Col>
                                        <Col
                                            lg='auto'
                                            className='mb-2 mb-lg-0 ml-xl-auto pl-xl-1'
                                        ></Col>
                                        <Col className='col-auto pl-lg-1'>
                                            <div className='search search-style-1 mx-lg-auto w-auto'>
                                                <InputGroup>
                                                    <Form.Control
                                                        type='text'
                                                        className='search-term'
                                                        placeholder='Caută'
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                    <InputGroup.Append>
                                                        <Button variant='default' type='submit'>
                                                            <i className='bx bx-search'></i>
                                                        </Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <PtTable
                                    className='table table-core-simple -striped mb-0'
                                    data={ajax.data}
                                    loading={loading}
                                    columns={customColumns ?? columns}
                                    pages={ajax.total}
                                    pageSize={pageSize ?? 12}
                                    manual
                                    onFetchData={fetchData}
                                    selectAll={selectAll}
                                    toggleAll={() => setSelectAll(!selectAll)}
                                    isSelected={(key) => isSelected(key)}
                                    toggleSelection={onSelectChange}
                                    onChangeRef={(ref) => setTableRef(ref)}
                                    totalRecords={ajax.totalRecords}
                                    totalFilteredRecords={ajax.totalFilteredRecords}
                                    isFiltered={ajax.isFiltered}
                                    getTrProps={getTrProps}
                                />

                                <div className='datatable-footer'>
                                    <Row className='align-items-center justify-content-between mt-3'>
                                        <Col md='auto' className='mb-3 mb-lg-0'>
                                            <div className='d-flex'>
                                                <Form.Control
                                                    as='select'
                                                    className='select-style-1 bulk-action w-auto mr-3'
                                                    value={bulk}
                                                    onChange={(e) => setBulk(e.target.value)}
                                                    style={{ minWidth: '120px' }}
                                                >
                                                    <option value=''>Acțiuni în masă</option>
                                                    <option value='delete'>Șterge</option>
                                                </Form.Control>
                                                <Button
                                                    href='#bulk-action'
                                                    className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                                    variant='light'
                                                    onClick={bulkAction}
                                                >
                                                    Aplică
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col lg='auto' className='mb-3 mb-lg-0'>
                                            <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

export default ClientSubscriptionsTable;