import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';
import { deleteClients } from '../../../api/clients';
import { getClientSubscription, updateClientSubscription } from '../../../api/client-subscriptions';
import ClientSubscriptionForm from './components/client-subscription-form';

export default function ClientSubscriptionDetails() {

    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        getClientSubscription(id).then((result) => {
            setSubscription(result);
        }).finally(() => {
            setLoading(false);
        });
        document.querySelector('body').classList.add('loaded');
    }, [])

    const updateItem = async (data) => {
        const result = await updateClientSubscription(data, id);
        if (result.status === 200 && result.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/client-subscriptions`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    const deleteItem = (e) => {
        e.preventDefault();
        setOpenModal(true);
    }

    async function deleteConfirm(result) {
        setOpenModal(false);
        if (result) {
            const checker = await deleteClients(id);
            if (checker.status === 200 && checker.data.error === 0) {
                navigate(`${process.env.PUBLIC_URL}/dashboard/client-subscriptions`);
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    return (
        loading ? (
            <Loader />
        ) : (
            <>
                <Breadcrumb
                    current='Detalii Client'
                    paths={[
                        {
                            name: 'Dashboard',
                            url: '/dashboard',
                        },
                        {
                            name: 'Clienti si Abonamente',
                            url: '#',
                        },
                        {
                            name: 'Abonamente',
                            url: '/dashboard/client-subscriptions',
                        },
                        {
                            name: 'Detalii Abonament',
                            url: '#',
                        },
                    ]}
                />
                <ClientSubscriptionForm
                    clientSubscription={subscription}
                    onSubmit={updateItem}
                    onDelete={deleteItem}
                />
                <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
            </>
        )
    )
}